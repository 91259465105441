import { mapState } from 'vuex'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export const demandMixins = {
  data () {
    return {
      assetArray: [],
      stationArray: [],
      positionArray: [],
      pagesizeSetting: [15, 25, 50, 100],
      visiblePreview: false,
      fileName: '',
      fileUrl: '',
      sortIndex: 0
    }
  },
  computed: {
    ...mapState({
      demandId: state => state.installSetting.demandId,
      orderInfo: state => state.installSetting.orderInfo,
      orderProductArray: state => state.installSetting.orderProductArray,
      demandStartDate: state => state.installSetting.demandStartDate,
      demandEndDate: state => state.installSetting.demandEndDate
      // tableData: state => state.installSetting.resourceData
    }),
    setMaxSide: {
      get () {
        return this.$store.state.installSetting.setMaxSide
      },
      set (val) {
        this.$store.commit('set_max_side', val)
      }
    }
  },
  methods: {
    htmlToSchedule (sDate, eDate) {
      return GetCurrentSchedule(sDate, eDate, false)
    },
    assetChange () {
      this.query.stationId = ''
      this.query.position = ''
      if (this.query.assetId) {
        const asset = this.assetArray.find(x => x.assetId === this.query.assetId)
        this.stationArray = asset ? asset.stationList : []
      } else {
        this.stationArray = []
      }
    },
    stataionChange () {
      this.query.position = ''
      if (this.query.stationId) {
        const station = this.stationArray.find(x => x.stationId === this.query.stationId)
        this.positionArray = station ? station.positionList : []
      } else {
        this.positionArray = []
      }
    }
  }
}
